body {
    margin: 0;
}

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 9999;
}

.modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 10px 0;
    border-radius: 3px;
    background: #ffffff;
}

.stageSwitcherList {
    list-style: none;
    padding: 0;
}

.stageSwitcherList li.stage {
    padding: 20px 15px;
    font-family: sans-serif;
    font-size: 18px;
    color: #000000;
}

.stageSwitcherList li.stage .checkbox {
    position: relative;
    border: 2px solid #000000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    float: right;
}

.stageSwitcherList li.stage .checkbox.checked:before {
    content: '';
    position: absolute;
    background: #000000;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    left: 2px;
    top: 2px;
}

.stageSwitcherList li.stage:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.empty-flag {
    display: inline-block;
}

.Loader {
    position: fixed;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
}

.Loader img {
    display: inline-block;
}

.Loader span {
    padding: 0 30px;
    margin-top: 30px;
    display: block;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
}
